@import "~@flaticon/flaticon-uicons/css/all/all";

html {
  height: 100%;
  box-sizing: border-box;
}
body,
#root {
  position: relative;
  margin: 0;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.custom-highlight {
  background-color: yellow;
  color: black;
}
